<template>
  <div class="signPriview">
    <ul class="list">
      <li class="item" v-for="(item, index) in fileList" :key="index">
        <img :src="item" alt="" />
      </li>
    </ul>
    <!-- <div class="footer">
      <van-button type="primary" block @click="close">关闭</van-button>
    </div> -->
  </div>
</template>
<script>
  import { initPinchZoom } from '@/utils/pinchZoom'
  import sassApi from '@/api/sass'

  export default {
    data() {
      return {
        fileList: [],
        appKey: '',
        contractNum: '',
        telephone: '',
        secret: '',
        timestamp: '',
        v: '',
      }
    },
    created() {
      const {
        appKey,
        contractNum,
        telephone,
        secret,
        timestamp,
        v,
      } = this.$route.query
      this.appKey = appKey
      this.contractNum = contractNum
      this.telephone = telephone
      this.secret = secret
      this.timestamp = timestamp
      this.v = v
      this.getContractImages()
    },
    mounted() {},
    methods: {
      getContractImages() {
        sassApi
          .getContractImages({
            appKey: this.appKey,
            contractNum: this.contractNum,
            telephone: this.telephone,
            secret: this.secret,
            timestamp: this.timestamp,
            v: this.v,
          })
          .then(res => {
            this.fileList = res.fileKeyList
          })
          .catch(err => {
            console.log(err)
          })
      },
      close() {
        window.location.href = 'about:blank'
        window.close()
      },
    },
  }
</script>
<style lang="less" scoped>
  .signPriview {
    // padding-bottom: 56px;
    .list {
      .item {
        img {
          width: 100%;
        }
      }
    }
    .footer {
      z-index: 103;
      position: fixed;
      background-color: #fff;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 56px;
      padding: 0 16px;
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      display: flex;
      align-items: center;
      .van-button {
      }
    }
  }
</style>
